var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative flex flex-col transition-colors duration-150 ease-in-out rounded-sm bg-gray-light group/card ring-1 ring-inset ring-transparent hover:bg-gray hover:ring-gray focus-within:ring-gray-dark",attrs:{"data-testid":"card-product","itemscope":"","itemtype":_vm.ESchema.Product}},[_c('header',{staticClass:"absolute flex items-start justify-between w-full"},[(_vm.shouldDisplayQualityMark)?_c('span',{staticClass:"absolute pt-3.5 -translate-x-1/2 left-1/2 text-xs",attrs:{"data-testid":"card-product-iqm"}},[_vm._v("★★★")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-1/2 pt-3 z-[1]"},[(_vm.shouldDisplayDiscount)?_c('BadgeDiscount',{attrs:{"value":_vm.discountPercentage}}):_vm._e(),_vm._v(" "),(_vm.isSoldOut)?_c('BadgeSoldOut'):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex justify-end w-1/2 pt-3 pr-2"},[(_vm.isUserSelectionsManager)?_c('ButtonAddToSelectionTriage',{staticClass:"flex self-start mt-px",attrs:{"product-id":_vm.productId}}):_vm._e(),_vm._v(" "),(_vm.shouldEnableWishlistControls)?_c('ProductWishlist',{attrs:{"product":_vm.product}}):_vm._e()],1)]),_vm._v(" "),_c('figure',{staticClass:"overflow-hidden"},[_c('NuxtImg',{staticClass:"object-contain h-auto transition-transform duration-150 ease-in-out group-hover/card:scale-105",class:{
        'w-4/5 mx-auto mt-6 max-h-[170px]': !_vm.isLarge,
        'w-full p-6': _vm.isLarge,
      },attrs:{"alt":_vm.product.title,"height":166,"itemprop":_vm.ESchemaProduct.Image,"loading":"lazy","sizes":"xs:85px sm:181px md:106px lg:148px xl:166px 2xl:332px","src":_vm.imageSource,"width":166}})],1),_vm._v(" "),_c('footer',{staticClass:"flex flex-col px-3 pt-2 pb-3"},[_c('BaseLink',{class:{
        'mb-1.5': _vm.shopRating,
        'mb-2': !_vm.shopRating,
      },attrs:{"href":_vm.pageHref,"is-expanded":"","is-unstyled":""},on:{"click":_vm.handleClick}},[_c(_vm.productTitleTag,{tag:"component",staticClass:"text-tiny line-clamp-2",attrs:{"data-testid":"card-product-title","itemprop":_vm.ESchemaProduct.Name}},[_vm._v("\n        "+_vm._s(_vm.product.title)+"\n      ")])],1),_vm._v(" "),_c('ProductRating',{staticClass:"mb-2",attrs:{"product":_vm.product}}),_vm._v(" "),_c('ProductPrice',{attrs:{"is-compact":"","is-user-pro":_vm.isUserPro,"product":_vm.product,"should-enable-microdata":""}}),_vm._v(" "),(_vm.shouldEnableWishlistControls)?_c('PortalTarget',{attrs:{"name":_vm.wishlistButtonPortalTargetName}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }