import { actionTree, getterTree, mutationTree } from 'typed-vuex';

import { ESelection as MutationType } from '@/domain/core/store/MutationType.enum';
import { Product, ProductSelectionTriage } from '@/domain/product/types';
import { EUserRole } from '@/domain/user/types';
import { accessorType } from '@/store';

export const state = (): { triageProducts: Product[] } => ({
  triageProducts: [],
});

export const getters = getterTree(state, {
  triageProductIds: ({ triageProducts }): Product['legacyId'][] => {
    const ids = triageProducts.map(({ legacyId }) => legacyId).filter(Boolean);

    return [...new Set(ids)];
  },
});

export const mutations = mutationTree(state, {
  [MutationType.ADD_PRODUCT_TO_TRIAGE_PRODUCTS]: (state, payload: Product): void => {
    state.triageProducts.push(payload);
  },
  [MutationType.REMOVE_PRODUCT_FROM_TRIAGE_PRODUCTS_BY_ID]: (state, payload: Product['id']): void => {
    state.triageProducts = state.triageProducts.filter(({ legacyId }) => legacyId !== payload);
  },
  [MutationType.SET_TRIAGE_PRODUCTS]: (state, payload: ProductSelectionTriage[]): void => {
    state.triageProducts = payload.map(({ product }) => product);
  },
});

export const actions = actionTree({ state, mutations }, {
  // Action callable by admins only.
  async fetchTriageContent(): Promise<void> {
    try {
      const accessor: typeof accessorType = this.app.$accessor;
      const { token } = accessor.user;
      const isSelectionsManager = accessor.user.roles.includes(EUserRole.SelectionManager);

      if (token && isSelectionsManager) {
        const products = await this.$repositories.selection.getTriageContent();

        accessor.selection.SET_TRIAGE_PRODUCTS(products);
      }
    } catch (err) {
      this.$errorMonitor.report(err, 'error');
    }
  },
});
