import merge from '~lodash.mergewith'
import * as CoreSdk from '~@sentry/core'
import { captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } from '~@sentry/browser'
import { extraErrorDataIntegration } from '~@sentry/integrations'
import { init } from '~@sentry/vue'

export { init }
export const SentrySdk = { ...CoreSdk, ...{ captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } }

/** @type {string[]} */
const DISABLED_INTEGRATION_KEYS = ["ReportingObserver"]

/**
 * @typedef {Parameters<typeof init>[0]} InitConfig
 * @param {import('@nuxt/types').Context} ctx
 * @return {Promise<InitConfig>}
 */
export function getConfig (ctx) {
  /** @type {InitConfig} */
  const config = {
    dsn:"https:\u002F\u002F072af25f3b0a4655bc01957a1264537b@o107511.ingest.sentry.io\u002F4503997804314624",
    environment:"prod",
    attachStacktrace:true,
    beforeSend:function(event) {
      if (event.level === 'warning') {
        return null;
      }

      const shouldIgnoreError = (errorEvent) => {
        return isNetworkError(errorEvent);
      };

      const typeErrorFetchFailedValues = new Set([
      'Failed to fetch',
      'NetworkError when attempting to fetch resource.',
      'Load failed']
      );

      const isNetworkError = (errorEvent) => {
        const exception = errorEvent.exception?.values?.[0];
        const now = Date.now();

        const isTypeError = exception?.type === 'TypeError';
        const hasTypeErrorFetchFailedValue = typeErrorFetchFailedValues.has(exception?.value || '');

        if (!isTypeError || !hasTypeErrorFetchFailedValue) {
          return false;
        }

        if (!errorEvent?.breadcrumbs?.length) {
          return false;
        }

        // We go from the back since the last breadcrumb is most likely the erroneous one
        for (let i = errorEvent.breadcrumbs.length - 1; i >= 0; i -= 1) {
          const breadcrumb = errorEvent.breadcrumbs?.[i];

          if (!breadcrumb) {
            continue;
          }

          if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > 5000) {// We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
            break;
          }

          if (isErroneousBreadcrumb(breadcrumb)) {
            return true;
          }
        }

        return false;
      };

      const isErroneousBreadcrumb = (breadcrumb) => {
        if (breadcrumb.level !== 'error' || breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch') {
          return false;
        }

        const breadcrumbUrl = breadcrumb.data?.url;

        if (!breadcrumbUrl) {
          return false;
        }

        const mutedUrls = [
        'ct.pinterest.com',
        'cdn.cookielaw.org',
        'px.ads.linkedin.com',
        'privacyportal-de.onetrust.com'];

        return mutedUrls.some((mutedUrl) => breadcrumbUrl.includes(mutedUrl));
      };

      return shouldIgnoreError(event) ? null : event;
    },
    release:"v1.5.77",
  }

  /** @type {NonNullable<InitConfig>['integrations']} */
  const resolvedIntegrations = [
    extraErrorDataIntegration(),
  ]

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
