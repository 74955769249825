import { SecurityToken } from '@/domain/core/SecurityService.interface';

export enum EUserRole {
  Admin = 'ROLE_ADMIN',
  B2BPro = 'ROLE_B2B_PRO',
  Expert = 'ROLE_PRODUCT_EXPERT',
  SelectionManager = 'ROLE_SELECTIONS_MANAGER',
  User = 'ROLE_USER',
}

interface OrderEntries {
  cashoutPrepared: number;
  confirmed: number;
  pending: number;
}

export interface UserPublic {
  firstName: string;
  hashedUser: string;
  id: string;
  isLocked: boolean;
  lastNameInitial: string;
  legacyId: number | null;
}

export interface UserPrivate {
  email: string;
  firstName: string;
  hashedUser: string;
  id: string;
  isLocked: boolean;
  isPro: boolean;
  lastName: string;
  lastNameInitial: string;
  legacyId: number;
  locale: string;
  profileId: string;
  roles: `${EUserRole}`[];
  shopId: string | null;
  source: string;
  token: SecurityToken;
}

export interface UserSubscriptions {
  isSubscribedNewsletter: boolean;
  isSubscribedSuggestedProducts: boolean;
  isSubscribedTexts: boolean;
  isSubscribedWishlistNotifications: boolean;
}

export interface UserStats {
  orderEntries: OrderEntries;
}

export interface LegacyUser {
  email: string;
  firstname?: string;
  id?: string;
  lastname?: string;
  locale: string;
  status: string;
  token?: string;
  user_id?: string;
}
